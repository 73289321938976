`<template lang="pug">
  v-card
    .planning-table__row-2
      .planning-table__row-2-head
        .planning-table__row-2-head-cell
          v-dropdown(
            ref="premixDropdown"
            width-auto)
            span {{ item.recipe_name | rationNameWrap }}
            template(slot="content")
              v-card(
                white
                secondary
                elevated)
                table.table.table--no-background.table--small.table--cell-overflow-hidden
                  thead.table__header
                    tr.table__row.table__row--header
                      th.table__cell {{ 'base.#' | translate }}
                      th.table__cell {{ 'base.ingredient' | translate }}
                      th.table__cell {{ 'base.percent_of_input' | translate }}
                      th.table__cell {{ 'base.cost_plan' | translate }}
                  tbody.table__body
                    tr.table__row.table__row--body.table__row--no-hover(
                      v-for="(ingredient, index) in item.recipe.proportions"
                      :key="index")
                      td.table__cell {{ index + 1 }}
                      td.table__cell(
                        :title="ingredient.ingredient_name")
                        | {{ ingredient.ingredient_name }}
                      td.table__cell {{ ingredient.rate }}%
                      td.table__cell {{ (item.plan_weight / 100 * ingredient.rate) | toPrice }}
                v-divider(secondary)

        .planning-table__row-2-head-cell {{ item.plan_weight | toFixed }} / {{ item.fact_weight | toFixed }}
        .planning-table__row-2-head-cell {{ item.started_at || '-' }}
        .planning-table__row-2-head-cell {{ calcTotalValue(batches, 'load_time') | sectotime }}
        .planning-table__row-2-head-cell {{ calcTotalValue(batches, 'mixing_time') | sectotime }}
        .planning-table__row-2-head-cell {{ item.fact_weight }}
        .planning-table__row-2-head-cell
          span(:style="{ color: statuses[item.status].color }")
            | {{ statuses[item.status].name | translate }}
          .planning-table__icon
            v-btn(
              v-if="'Technologist' === user.user.role"
              icon
              small
              :disabled="disabled"
              @click="onSelect")
              v-icon(icon="cog")
            v-btn(
              icon
              small
              @click="toggled = !toggled")
              v-chevron(
                :value="toggled")

      //- BATCHES LIST
      .planning-table__row-2-body(v-if="toggled")
        table.table.table--small.table--no-background.table--cell-overflow-hidden
          thead.table__header
            tr.table__row.table__row--header
              th.table__cell {{ 'base.ration' | translate }}
              th.table__cell {{ 'base.silage' | translate }}
              th.table__cell {{ 'base.weight' | translate }} [{{ 'units.kg' | translate }}]#[br] {{ 'base.plan_fact' | translate }}
              th.table__cell {{ 'base.deviation' | translate }}#[br] [{{ 'units.kg' | translate }}] / [%]
              th.table__cell {{ 'base.start_time' | translate }}  ({{ 'units.hi' | translate}})
              th.table__cell {{ 'base.duration' | translate }} ({{ 'units.his' | translate }})
              th.table__cell {{ 'base.mixing_time' | translate }} ({{ 'units.his' | translate }})
              th.table__cell {{ 'base.unloaded' | translate }} [{{ 'units.kg' | translate }}]
              th.table__cell {{ 'base.status' | translate }}
          tbody.table__body
            tr.table__row.table__row--body.table__row--no-hover(
              v-for="(batch, index) in batches"
              :key="batch.id")
              td.table__cell {{ item.ration.name }}
              td.table__cell(
                :title="item.silages_list | liststr('number')")
                | {{ item.silages_list | liststr('number') }}
                | {{ item.silages_list.length === 0 ? '---' : ''}}

              //- BATCH DETAIL
              td.table__cell.table__cell--overflow-visible
                v-dropdown(
                  center
                  width-auto)
                  span.text-underline {{ batch.plan_weight }} / {{ batch.fact_weight | toFixed }}
                  template(slot="content")
                    v-card(
                      white
                      secondary
                      elevated)
                      table.table.table--no-background.table--small
                        thead.table__header
                          tr.table__row.table__row--header
                            th.table__cell {{ 'base.#' | translate }}
                            th.table__cell {{ 'base.ingredient' | translate }}
                            th.table__cell {{ 'base.weight' | translate }}#[br] {{ 'base.plan_fact' | translate }}
                            th.table__cell {{ 'base.deviation' | translate }}#[br] [{{ 'units.kg' | translate }} / %]
                            th.table__cell {{ 'base.start_time' | translate }}
                            th.table__cell {{ 'base.duration' | translate }} ({{ 'units.his' | translate }})
                        tbody.table__body
                          tr.table__row.table__row--body.table__row--no-hover(
                            v-for="(item, index) in batch.ingredients"
                            :key="index")
                            td.table__cell {{ index + 1 }}
                            td.table__cell {{ item.ingredient_name }}
                            td.table__cell {{ item.plan_weight | toFixed }} / {{ item.fact_weight| toFixed }}
                            td.table__cell {{ calcDifValue(item) }}
                            td.table__cell {{ item.time }}
                            td.table__cell {{ item.loaded_time }}
                          tr.table__row.table__row--body.table__row--no-hover
                            td.table__cell {{ batch.ingredients.length + 1 }}
                            td.table__cell {{ 'base.mixing' | translate }}
                            td.table__cell {{  }}
                            td.table__cell {{  }}
                            td.table__cell {{ batch.start_mixing_time }}
                            td.table__cell {{ batch.mixing_time | sectotime }}

              td.table__cell.table__cell--overflow-visible
                v-dropdown(
                  center
                  width-auto)
                  span.text-underline(:style="{ color: batch.dif_weight < 0 ? colors.red : batch.dif_weight > 0 ? colors.green : colors.gray }") {{ batch.dif_weight | toFixed }} / {{ batch.dif_percent }}
                  template(slot="content")
                    v-card(
                      white
                      secondary
                      elevated)
                      table.table.table--no-background.table--small
                        thead.table__header
                          tr.table__row.table__row--header
                            th.table__cell {{ 'base.#' | translate }}
                            th.table__cell {{ 'base.ingredient' | translate }}
                            th.table__cell {{ 'base.weight' | translate }}#[br] {{ 'base.plan_fact' | translate }}
                            th.table__cell {{ 'base.deviation' | translate }}#[br] [{{ 'units.kg' | translate }} / %]
                            th.table__cell {{ 'base.start_time' | translate }}
                            th.table__cell {{ 'base.duration' | translate }} ({{ 'units.his' | translate }})
                        tbody.table__body
                          tr.table__row.table__row--body.table__row--no-hover(
                            v-for="(item, index) in batch.ingredients"
                            :key="index")
                            td.table__cell {{ index + 1 }}
                            td.table__cell {{ item.ingredient_name }}
                            td.table__cell {{ item.plan_weight | toFixed }} / {{ item.fact_weight | toFixed }}
                            td.table__cell {{ calcDifValue(item) }}
                            td.table__cell {{ item.time }}
                            td.table__cell {{ item.loaded_time }}
                          tr.table__row.table__row--body.table__row--no-hover
                            td.table__cell {{ batch.ingredients.length + 1 }}
                            td.table__cell {{ 'base.mixing' | translate }}
                            td.table__cell {{  }}
                            td.table__cell {{  }}
                            td.table__cell {{ batch.start_mixing_time }}
                            td.table__cell {{ batch.mixing_time | sectotime }}
              td.table__cell.table__cell--overflow-visible
                v-dropdown(
                  center
                  width-auto)
                  span.text.text-underline {{ batch.time }}
                  template(slot="content")
                    v-card(
                      white
                      secondary
                      elevated)
                      table.table.table--no-background.table--small
                        thead.table__header
                          tr.table__row.table__row--header
                            th.table__cell {{ 'base.#' | translate }}
                            th.table__cell {{ 'base.ingredient' | translate }}
                            th.table__cell {{ 'base.weight' | translate }}#[br] {{ 'base.plan_fact' | translate }}
                            th.table__cell {{ 'base.deviation' | translate }}#[br] [{{ 'units.kg' | translate }} / %]
                            th.table__cell {{ 'base.start_time' | translate }}
                            th.table__cell {{ 'base.duration' | translate }} ({{ 'units.his' | translate }})
                        tbody.table__body
                          tr.table__row.table__row--body.table__row--no-hover(
                            v-for="(item, index) in batch.ingredients"
                            :key="index")
                            td.table__cell {{ index + 1 }}
                            td.table__cell {{ item.ingredient_name }}
                            td.table__cell {{ item.plan_weight | toFixed }} / {{ item.fact_weight | toFixed }}
                            td.table__cell {{ calcDifValue(item) }}
                            td.table__cell {{ item.time }}
                            td.table__cell {{ item.loaded_time }}
                          tr.table__row.table__row--body.table__row--no-hover
                            td.table__cell {{ batch.ingredients.length + 1 }}
                            td.table__cell {{ 'base.mixing' | translate }}
                            td.table__cell {{  }}
                            td.table__cell {{  }}
                            td.table__cell {{ batch.start_mixing_time }}
                            td.table__cell {{ batch.mixing_time | sectotime }}
              td.table__cell.table__cell--overflow-visible
                v-dropdown(
                  center
                  width-auto)
                  span.text.text-underline {{ batch.load_time | sectotime }}
                  template(slot="content")
                    v-card(
                      white
                      secondary
                      elevated)
                      table.table.table--no-background.table--small
                        thead.table__header
                          tr.table__row.table__row--header
                            th.table__cell {{ 'base.#' | translate }}
                            th.table__cell {{ 'base.ingredient' | translate }}
                            th.table__cell {{ 'base.weight' | translate }}#[br] {{ 'base.plan_fact' | translate }}
                            th.table__cell {{ 'base.deviation' | translate }}#[br] [{{ 'units.kg' | translate }} / %]
                            th.table__cell {{ 'base.start_time' | translate }}
                            th.table__cell {{ 'base.duration' | translate }} ({{ 'units.his' | translate }})
                        tbody.table__body
                          tr.table__row.table__row--body.table__row--no-hover(
                            v-for="(item, index) in batch.ingredients"
                            :key="index")
                            td.table__cell {{ index + 1 }}
                            td.table__cell {{ item.ingredient_name }}
                            td.table__cell {{ item.plan_weight | toFixed }} / {{ item.fact_weight | toFixed }}
                            td.table__cell {{ calcDifValue(item) }}
                            td.table__cell {{ item.time }}
                            td.table__cell {{ item.loaded_time }}
                          tr.table__row.table__row--body.table__row--no-hover
                            td.table__cell {{ batch.ingredients.length + 1 }}
                            td.table__cell {{ 'base.mixing' | translate }}
                            td.table__cell {{  }}
                            td.table__cell {{  }}
                            td.table__cell {{ batch.start_mixing_time }}
                            td.table__cell {{ batch.mixing_time | sectotime }}
              td.table__cell {{ batch.mixing_time | sectotime }}
              td.table__cell(style="text-align: center;")
                  span {{ reducer(batch.fact_weight, 0) }}
                  v-btn(
                    v-if="'in_progress' == batch.status && 'Technologist' == user.user.role"
                    icon
                    small
                    @click="stopProcess(item, batch)")
                    v-icon(icon="stop-circle", color="red")
              td.table__cell
                span(:style="{ color: statuses[batch.status].color }")
                  | {{ statuses[batch.status].name | translate }}

              //- TOTAL DETAILS
              tr.table__row.table__row--body.table__row--no-hover.table__row--total-values(v-if="batches.length")
                td.table__cell {{ 'base.total' | translate }}:
                td.table__cell
                td.table__cell {{ calcTotalValue(batches, 'plan_weight') | toFixed }} / {{ calcTotalValue(batches, 'fact_weight') | toFixed }}
                td.table__cell
                  span(:style="{ color: calcTotalValue(batches, 'dif_weight') < 0 ? colors.red : colors.green }")
                    | {{ calcTotalValue(batches, 'dif_weight') | toFixed }} / {{ Math.floor((calcTotalValue(batches, 'fact_weight') - calcTotalValue(batches, 'plan_weight')) / calcTotalValue(batches, 'plan_weight') * 10000) / 100 | toFixed}}
                td.table__cell
                td.table__cell {{ calcTotalValue(batches, 'load_time') | sectotime }}
                td.table__cell {{ calcTotalValue(batches, 'mixing_time') | sectotime }}
                td.table__cell(style="text-align: center;") {{ calcTotalValue(batches, 'fact_weight') }}
                td.table__cell
              tr.table__row.table__row--body.table__row--no-hover.table__row--total-values(v-else)
                td.table__cell(
                  colspan="9"
                  width="100%"
                  style="text-align: center; max-width: 100%")
                  | {{ 'base.no_batches' | translate }}

    confirm-modal(ref="confirmModal")
</template>

<script>
import { mapGetters } from 'vuex'
import { COLORS, PLANNING_STATUSES } from '@/util/consts.js'
import timetables from '@/api/timetables'
import ConfirmModal from '@/components/common/confirm-modal/ConfirmModal'

const reducer = (accumulator, value) => Math.floor((accumulator + value) * 10) / 10

export default {
  name: 'PlanningTableRow2',

  components: { ConfirmModal },

  props: {
    disabled: Boolean,
    item: Object
  },

  data: () => ({
    toggled: false,
    colors: COLORS,
    statuses: PLANNING_STATUSES
  }),

  filters: {
    rationNameWrap (value) {
      const index = value.indexOf('+')
      return index
        ? value.splice(index + 1, 0, ' ')
        : value
    }
  },

  computed: {
    batches () {
      return (this.item.batches || []).map(item => ({
        ...item,
        dif_weight: item.fact_weight - item.plan_weight,
        dif_percent: Math.floor((item.fact_weight - item.plan_weight) / item.plan_weight * 1000) / 10
      }))
    },

    ...mapGetters(['user', 'currentFarm'])
  },

  methods: {
    onSelect () {
      this.$emit('select', this.item)
    },

    calcTotalValue (items, field) {
      return items
        .map(item => +item[field])
        .reduce(reducer, 0)
    },

    calcDifValue (item) {
      if (!item.fact_weight) {
        return '- / -'
      }
      item.dif_weight = Math.floor((item.fact_weight - item.plan_weight) * 100) / 100
      item.dif_percent = Math.floor((item.fact_weight - item.plan_weight) / item.plan_weight * 1000) / 10
      return `${item.dif_weight} / ${item.dif_percent}`
    },

    reducer (accumulator, value) {
      return Math.floor((accumulator + value) * 10) / 10
    },

    async stopProcess (item, batch) {
      const isConfirm = await this.$refs.confirmModal.show()
      if (isConfirm) {
        const { error } = await timetables.stopBatch(this.currentFarm, item.timetable_id, batch.id)
        if (!error) {
          this.$router.go(0)
        }
      }
    }
  }
}
</script>

<style lang="scss">
  @import '@/sass/abstracts/_variables.scss';

  .planning-table__row-2 {

    &-head {
      position: relative;
      display: flex;
      gap: 8px;
      align-items: center;
      gap: 8px;
      padding: .8rem 1.5rem;

      &-cell {
        &:nth-child(1) {
          width: 20%;
          margin-right: 2%;
          text-decoration: underline;
        }

        &:nth-child(2) {
          width: 14%;
        }

        &:nth-child(3) {
          width: 11%;
        }

        &:nth-child(4) {
          width: 14%;
        }

        &:nth-child(5) {
          width: 15%;
        }

        &:nth-child(6) {
          width: 15%;
        }

        &:nth-child(7) {
          width: 20%;
        }

        &:last-child {
          padding-right: 64px;
        }
      }
    }

    &-body {
      border-top: .6rem solid $color-gray-light-2;
    }

    .table__row--body:last-child {
      border-bottom: none;
    }

    .planning-table__icon {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
    }
  }
</style>
